// boostrap variable overrides

$body-bg: #f8fafc;
$body-color: #3d3d3d;

$theme-colors: (
  // 'primary': #080808,
  // 'secondary': #404040,
);

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-textarea {
  .wrapper-class {
    border: 1px solid;
    margin-bottom: 1rem;
    .editor-class {
      background-color: white;
      min-height: 140px;
    }
  }
}

.feature-video {
  header {
    justify-content: flex-start !important;
  }
}

@import '~bootstrap/scss/bootstrap';
@import './components/common/fileuploader/style';
@import './components/header/style';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
}

.text-600 {
  font-weight: 600;
}
.text-400 {
  font-weight: 400;
}

.form-group {
  margin-bottom: 32px;
}

.navbar {
  background-color: $white !important;
  height: 65px;
  box-shadow: 0 0 6px 0 #80808075;
  margin-bottom: 35px;
  .navbar-brand {
    font-weight: 700;
    font-size: 20px;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: $black;
}

.form-control {
  height: calc(1.5em + 0.75rem + 2px);
  border-color: #5a5959;
}

label {
  color: #3c3b3b;
}

.cursor {
  cursor: pointer;
}

.rdt_TableHeadRow {
  .rdt_TableCol_Sortable {
    font-weight: 600;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
  }
}

.rdt_TableCell {
  font-size: 16px;
  div {
    font-size: 16px;
  }
}

.single-select {
  & > div {
    & > div:first-child {
      width: 200px;
    }
  }
}

.filter {
  position: relative;
  display: flex;
  input {
    padding-right: 40px;
  }
  button {
    position: absolute;
    right: 0;
    height: 100%;
    background: transparent;
    border: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.cover {
  object-fit: cover;
}

.upload-file {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  label {
    margin-bottom: 0;
  }
  button {
    position: absolute;
    right: 15px;
    border-radius: 15px;
  }
}

.upload-file-image {
  .upload-file {
    background: url(../src/assets/upload-image.jpg) no-repeat;
    width: 100%;
    height: 150px;
    background-color: gray;
    label,
    button {
      // visibility: hidden;
      opacity: 0;
    }
  }
}

.text-danger {
  font-size: 12px;
}
.uploadButton {
  border: 1px solid #000000;
  line-height: 24px;
  cursor: pointer;
}

.multiselect,
.single-select,
.tags,
.participants-container {
  input {
    height: 32px;
    border-color: #5a5959 !important;
  }
}

.btn {
  // border-radius: 20px;
}

.btn-add {
  padding-left: 32px;
  padding-right: 32px;
}

.rdt_TableHeadRow .rdt_TableCol_Sortable {
  font-weight: 700;
}
.company-featured-videos-container {
  padding: 0 30px;
  label {
    font-weight: 600;
  }
  .form-group {
    .btn-primary {
      margin-top: 32px;
      width: 100% !important;
      height: 38px;
    }
  }
}
.css-1pahdxg-control {
  cursor: initial;
  .css-2613qy-menu {
    display: block !important;
  }
}
.css-yk16xz-control {
  cursor: initial;
}
.css-4ljt47-MenuList {
  padding: 0 !important;
  overflow-y: initial !important;
  div {
    width: auto !important;
    overflow-y: hidden;
  }
  .css-9gakcf-option {
    cursor: pointer;
  }
}
.css-26l3qy-menu {
  .css-9gakcf-option {
    width: 239px !important;
  }
  .css-1n7v3ny-option {
    cursor: pointer;
    width: 239px !important;
  }
}

.autocomplete {
  .css-4ljt47-MenuList {
    overflow-x: auto !important;
  }
  .css-9gakcf-option {
    width: auto !important;
  }
  .css-1n7v3ny-option {
    cursor: pointer;
    width: auto !important;
  }
}
.css-1pahdxg-control {
  cursor: initial;
}
.navbar-nav {
  .navDiv {
    .nav-link {
      &.active {
        color: $blue;
      }
    }
  }
}

.evIOwU {
  div {
    &:first-child {
      -webkit-box-orient: vertical;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@import 'pages/video/style';
