.video-thumb {
    display: flex;
    max-width: 160px;
    width: 100%;
    height: 90px;
    margin-right: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.video-img  {
    width: 100%
}

.time-stamp {
    background: rgba(0, 0, 0, 0.8);
    color: $white;
    width: auto;
    height: 22px;
    font-size: 14px !important;
    padding-left: 5px;
    padding-right: 5px;
}

.video-thumb {
    position: relative;
    .time-stamp {
        position: absolute;
        top: 0;
        right: -3px;
    }
}

//Tabs
.custom-tab {
    display: flex;
}

.custom-tab--item {
    
    cursor: pointer;
    a {
        color: $secondary;
        display: block;
        &:hover {
            text-decoration: none;
        }
    }
}

.custom-tab--link {
    border-bottom: 2px solid $primary;
    display: block;
    color: $primary;
    &:hover {
        text-decoration: none;
        color: $primary;
    }
}

.custom-tab--text {
    font-size: 23px;
    margin-bottom: 0;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
}