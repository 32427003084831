.uploadButton {
    border: 1px solid #000000;
    // padding: 5px;
    line-height: 24px;
    cursor: pointer;
}

.multiselect, .single-select, .tags, .participants-container {
    input {
        height: 32px;
        border-color: #5A5959 !important;
    }
}