.navDiv{
    flex-direction: row;
    flex: 1;
    display: flex
}

.nav-admin {
    .dropdown-menu {
        right: 0;
        left: inherit;
    }
}